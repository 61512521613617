.footer-text {
  margin-top: 30px;
  text-align: center;
}

.invisible-input{
  border: 'none';
  background-color: 'blue';
  font-family: 'inherit';
  font-size: 'inherit';
  color: 'inherit';
  line-height: 'inherit';
  padding: 0;
  margin: 0;
  outline: 'none';
  width: 'auto';
}

.gradient-bg {
  background-image: linear-gradient(#070039, #13066C);
}

.gradient-bg-alt {
  background-image: linear-gradient( #13066C, #070039);
}

.glow-text {
  text-shadow: 0 0 4px #26ffe2; /* Adjust the values to control the glow effect */
}

a {
  color: #a233e6;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

h1, h2, h3{
  color: #daadf5;
}

.button-53 {
  margin-top:50px;
  background-color: #daadf5;
  border: 0 solid #E5E7EB;
  box-sizing: border-box;
  color: #000000;
  display: flex;
  font-family: ui-sans-serif,system-ui,-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size: 1rem;
  font-weight: 700;
  justify-content: center;
  line-height: 1.75rem;
  padding: .75rem 1.65rem;
  position: relative;
  text-align: center;
  text-decoration: none #000000 solid;
  text-decoration-thickness: auto;
  width: 100%;
  max-width: 460px;
  position: relative;
  cursor: pointer;
  transform: rotate(-1deg);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-53:focus {
  outline: 0;
}

.button-53:after {
  content: '';
  position: absolute;
  border: 1px solid #000000;
  bottom: 4px;
  left: 4px;
  width: calc(100% - 1px);
  height: calc(100% - 1px);
}

.button-53:hover:after {
  bottom: 2px;
  left: 2px;
}

@media (min-width: 768px) {
  .button-53 {
    padding: .75rem 3rem;
    font-size: 1.25rem;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

body {
  font-family: "Urbanist", sans-serif;
}

@font-face {
  font-family: 'Neon';
  src: url('../Fonts/neuropol-nova.ttf');
}

.white-glow-button, .green-glow-button, .grey-glow-button {
  padding: 3px 20px;
  font-size: 16px;
  color: white;
  background-color: transparent;
  border-radius: 10px;  /* Adjust for more or less rounding */
  cursor: pointer;
  transition: 0.3s ease;
  min-width:100px
}

.white-glow-button {
  border: 2px solid white;
}
.white-glow-button:hover {
  box-shadow: 0 0 10px white;
  color: white;
}

.green-glow-button {
  border: 2px solid #26ffe2;
}
.green-glow-button:hover {
  box-shadow: 0 0 10px #26ffe2;
  color: white;
}

.green-glow-button-submit {
  padding: 5px 20px;
  font-size: 16px;
  color: white;
  background-color: transparent;
  border-radius: 10px;  /* Adjust for more or less rounding */
  cursor: pointer;
  transition: 0.3s ease;
  min-width:100px
}

.green-glow-button-submit {
  border: 2px solid #26ffe2;
}
.green-glow-button-submit:hover {
  box-shadow: 0 0 10px #26ffe2;
  color: white;
}

.grey-glow-button {
  border: 2px solid #747474;
}
.grey-glow-button:hover {
  box-shadow: 0 0 10px #747474;
  color: white;
}


.green-glow-button-large {
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: transparent;
  border-radius: 10px;  /* Adjust for more or less rounding */
  cursor: pointer;
  transition: 0.3s ease;
  width: 100%;
}
.green-glow-button-large {
  border: 2px solid #26ffe2;
}
.green-glow-button-large:hover {
  box-shadow: 0 0 10px #26ffe2;
  color: white;
}


.grey-button-submit {
  padding: 5px 20px;
  font-size: 16px;
  color: white;
  background-color: transparent;
  border-radius: 10px;  /* Adjust for more or less rounding */
  cursor: pointer;
  transition: 0.3s ease;
  min-width:100px;
  font-weight: bold;
}

.grey-button-submit {
  color: #747474;
  border: 2px solid #747474;
}

.green-glow-button-menu {
  padding: 0px 20px;
  font-size: 16px;
  color: rgb(152, 152, 152);
  background-color: transparent;
  border-radius: 10px;  /* Adjust for more or less rounding */
  cursor: pointer;
  transition: 0.3s ease;
  min-width:100px
}

.green-glow-button-menu {
  border: 2px solid #26ffe2;
}
.green-glow-button-menu:hover {
  box-shadow: 0 0 10px #26ffe2;
  color: white;
}


.white-glow-button-menu {
  padding: 0px 20px;
  font-size: 16px;
  color: #26ffe2;
  background-color: transparent;
  border-radius: 10px;  /* Adjust for more or less rounding */
  cursor: pointer;
  transition: 0.3s ease;
  min-width:100px
}

.white-glow-button-menu {
  border: 1px solid #26ffe2;
}
.white-glow-button-menu:hover {
  box-shadow: 0 0 10px #26ffe2;
  color: white;
}

.parallax-bg {
  background-image: url("../Images/triathlon-dark.jpg");
  background-attachment: fixed; /* Creates the parallax effect */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}



.full-width-dropdown .dropdown-menu {
  width: 100% !important;  /* Ensure the dropdown menu takes full width */
}

.full-width-dropdown .dropdown-item {
  width: 100%;  /* Ensure each item takes full width */
  text-align: center;  /* Optional: Align text to the left */
}