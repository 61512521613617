/* Hide on larger screens */
.mobile-only {
    display: none;
  }
  
  /* Show only on mobile */
  @media (max-width: 768px) {
    .mobile-only {
      display: inline-block;
    }
  }
  