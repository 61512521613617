.topnav {
  position: fixed; /* Fixes the navbar at the top */
  top: 0;          /* Aligns the navbar to the top of the viewport */
  width: 100%;     /* Ensures the navbar takes up the full width */
  background-color: #333;
  z-index: 1000;   /* Ensures the navbar stays on top of other elements */
}
  
.topnav span {
float: left;
color: #26ffe1;
text-align: center;
padding: 14px 16px;
text-decoration: none;
font-size: 17px;
float: right;
cursor:pointer;
}
  
.topnav span:hover {
  background-color: #0c0251;
  color: #ddd;
}
  
.topnav span.active {
  background-color: #0c0251;
  color: #26ffe1;
}