@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');

/* Basic table styling */
table {
    width: 100%;
    border-collapse: separate; /* Separate the borders so border-radius works */
    border-spacing: 0; /* Remove gaps between cells */
    border-radius: 10px; /* Apply border-radius to table */
    overflow: hidden; /* Make sure content stays inside the rounded corners */
    background-color: #231c59; /* Black with 50% opacity */
}

.no-bg{
    background-color: transparent !important;
}
  
/* Table header styling */
th {
    background-color: #231c59; /* Black with 50% opacity */
    color: #26ffe2;
    font-family: "Urbanist", sans-serif;
    vertical-align: top; /* Aligns text to the top of the cell */
}

.table-div{
    background-color: #231c59;
    padding: 30px;
    margin-bottom: 50px;
    border-radius: 20px;
}


  
/* Table body styling */
td {
    background-color: #231c59; /* Black with 50% opacity */
    line-height: 2em;
    font-family: "Urbanist", sans-serif;
    color: rgba(256, 256, 256, 0.7);
}

.exercise-type{
    color: #ffffff;
    font-weight: lighter;
    font-size: 0.8em;
    margin-top: -3px;
    line-height: 1.2em;
}