.table-container {
    width: 100%;
    overflow-x: auto; /* Enables horizontal scrolling */
  }
  
  .responsive-table {
    width: 100%; /* Table takes full width inside the container */
    border-collapse: collapse; /* Optional for cleaner border style */
  }
  
  @media screen and (max-width: 992px) {
    .responsive-table {
      width: auto; /* Allows table to exceed container width on smaller screens */
    }
  }

/* For WebKit-based browsers (Chrome, Safari, Edge) */
.table-container::-webkit-scrollbar {
    width: 12px;   /* Width of the vertical scrollbar */
    height: 12px;  /* Height of the horizontal scrollbar */
  }
  
  .table-container::-webkit-scrollbar-track {
    background-color: #231c59; /* Scrollbar track background color */
  }
  
  .table-container::-webkit-scrollbar-thumb {
    background-color: rgba(256, 256, 256, 0.7); /* Scrollbar thumb (draggable part) color */
    border-radius: 6px; /* Optional: Make thumb rounded */
    border: 3px solid #231c59;
  }
  
  /* Shrink scrollbar buttons (arrows) to hide them */
  .table-container::-webkit-scrollbar-button {
    width: 0; /* Set width to 0 to effectively hide buttons */
    height: 0; /* Set height to 0 to effectively hide buttons */
    display: block; /* Ensure they are still technically "present" but invisible */
  }
  
  /* For Firefox */
  .table-container {
    scrollbar-color: rgba(256, 256, 256, 0.7) #231c59; /* Thumb color #26ffe2, track color #231c59 */
    scrollbar-width: thin; /* Optional: Makes scrollbar thinner */
  }
  