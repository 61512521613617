#custom-dropdown {
    color: white !important;
    border: 2px solid white !important;
    border-radius: 10px !important;
    background-color: transparent !important;
    padding: 5px 20px;
    transition: 0.3s ease;
    width: 100%;
}

#custom-dropdown:hover {
    box-shadow: 0 0 10px white;
    color: white !important;
}

#custom-dropdown-green {
    color: white !important;
    border: 2px solid #26ffe2 !important;
    border-radius: 10px !important;
    background-color: transparent !important;
    padding: 5px 20px;
    transition: 0.3s ease;
    width: 100%;
}

#custom-dropdown-green:hover {
    box-shadow: 0 0 10px #26ffe2;
    color: white !important;
}


.dropdown-menu {
    background-color: white; /* Info variant background */
}

.dropdown-item {
    color: rgb(48, 48, 48);
}

#smaller-dropdown {
    color: white !important;
    border: 2px solid white !important;
    border-radius: 10px !important;
    background-color: transparent !important;
    padding: 5px 10px;
    transition: 0.3s ease;
    width:60px;
}

#smaller-dropdown:hover {
    box-shadow: 0 0 10px white;
    color: white !important;
}