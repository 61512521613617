.parallax {
    /* The image used */
    background-image: url("../Images/triathlon.jpg");
  
    /* Set a specific height */
    min-height: 500px; 
  
    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    width: 100vw;
    height: 100vh; /* Adjust height as needed */
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff
  }

  body {
    overflow-x: hidden;
  }

  html {
    scroll-behavior: smooth;
  }

  .video-container {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    overflow: hidden;
    border-radius: 10px;
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
}
  