.edit-glow-button, .green-glow-button, .grey-glow-button {
    padding: 3px 7px;
    font-size: 16px;
    color: white;
    background-color: transparent;
    border-radius: 10px;  /* Adjust for more or less rounding */
    cursor: pointer;
    transition: 0.3s ease;
  }
  
  .edit-glow-button {
    border: 2px solid #26ffe2;
  }
  .edit-glow-button:hover {
    box-shadow: 0 0 10px #26ffe2;
    color: white;
  }