#profile-datePicker{
    border: 1px solid rgb(38, 255, 226);
    box-shadow: 0 0 10px rgba(38, 255, 226, 0.7); /* Adjust values for glow effect */
}

.missing-field{
    border: 1px solid #26ffe2;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(38, 255, 226, 0.7); /* Adjust values for glow effect */
}

#custom-dropdown-white {
    color: black !important;
    border: 2px solid white !important;
    border-radius: 10px !important;
    background-color: white !important;
    padding: 5px 20px;
    transition: 0.3s ease;
    width: 100%;
}

#custom-dropdown-white:hover {
    box-shadow: 0 0 10px white;
    color: black !important;
}