.checkout-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.checkout-card {
  background: white;
  padding: 20px 30px;
  top: -30px;
  width: 100%;
  max-width: 300px;
  border-radius: 12px;
  box-shadow: 3px 3px 60px 0px rgba(0, 0, 0, 0.1);
}

.checkout-columns {
  display: flex;
}

.checkout-columns > * {
  margin-right: 15px;
}

.checkout-field {
  height: 40px;
  border-bottom: 1px solid lightgray;
}

.checkout-field.is-onfocus {
  border-color: black;
}

.checkout-field.is-empty {
  border-color: orange;
}

.checkout-field.is-invalid {
  border-color: red;
}

.checkout-field.is-valid {
  border-color: green;
}

#card-pan {
  margin-bottom: 30px;
}

.checkout-submit {
  background: green;
  cursor: pointer;
  width: 200px;
  margin-top:30px;
  color: white;
  outline: 0;
  font-size: 14px;
  border: 0;
  border-radius: 30px;
  text-transform: uppercase;
  font-weight: bold;
  padding: 15px 0;
  transition: background 0.3s ease;
  margin-right:20px;
}

.checkout-clear {
  background: green;
  cursor: pointer;
  width: 100px;
  margin-top:30px;
  color: white;
  outline: 0;
  font-size: 14px;
  border: 0;
  border-radius: 30px;
  text-transform: uppercase;
  font-weight: bold;
  padding: 15px 0;
  transition: background 0.3s ease;
}

.checkout-buttons {
  display: flex;
}

.checkout-info {
  width: 600px;
  word-wrap:break-word;
  margin-top: 20px;
  color: green;
}