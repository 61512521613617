#dropdown-width{
    width: 100%;
}

#exercise-type-dropdown{
    width: 100%;
}

.high-z-index {
    z-index: 9999; /* Adjust as needed */
    position: relative; /* Make sure it's positioned relative/absolute */
}