.modal-dark .modal-content {
    background-image: linear-gradient(#070039, #13066C);
    color: white; /* Light text */
    border-color: #454d55; /* Darker border */
  }
  
  .modal-dark .modal-header,
  .modal-dark .modal-footer {
    border-color: #454d55; /* Border color */
  }
  
  .modal-dark .btn-close {
    filter: invert(1); /* Light close button */
  }

  .btn-close {
    filter: invert(1); /* Makes the button white */
}

.btn-close:focus, .btn-close:hover {
    filter: invert(1); /* Ensures hover and focus states are also white */
    outline: none;
  box-shadow: none;
}

.sidebar-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.sidebar-content {
  flex-grow: 1; /* This will make the content take up all the available space */
}

.logout-row {
  margin-top: auto; /* This pushes the logout button to the bottom */
}